@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
  .paused {
    animation-play-state: paused;
  }
}
@font-face {
  font-family: "Gotham-thin";
  src: url("../public/fonts/Gotham-Font/Gotham-Thin.otf");
}
@font-face {
  font-family: "Gotham-bold";
  src: url("../public/fonts/Gotham-Font/Gotham-Bold.otf");
}
@font-face {
  font-family: "Gotham-light";
  src: url("../public/fonts/Gotham-Font/Gotham-Light.otf");
}
@font-face {
  font-family: "Gotham-book";
  src: url("../public//fonts/Gotham-Font/GothamBook.ttf");
}

* {
  border-style: none;
}